import { useQuery, ApolloError } from '@apollo/client';
import { gql } from 'graphql-tag';
import { GetArrivalDisplaySettings } from './__generated__/GetArrivalDisplaySettings';

type ArrivalDisplaySettingsOutput = {
  badgePrinterIPAddress?: string;
  guestSelfRegistrationEnabled?: boolean;
  guestImageCaptureEnabled?: boolean;
  loading: boolean;
  error?: ApolloError;
};

export const GET_ARRIVAL_DISPLAY_SETTINGS = gql`
  query GetArrivalDisplaySettings {
    getArrivalDisplaySettings {
      badgePrinterIPAddress
      guestSelfRegistrationEnabled
      guestImageCaptureEnabled
    }
  }
`;

export const useArrivalDisplaySettings = (): ArrivalDisplaySettingsOutput => {
  const { data, loading, error } = useQuery<GetArrivalDisplaySettings>(
    GET_ARRIVAL_DISPLAY_SETTINGS,
    { fetchPolicy: 'cache-and-network' }
  );
  const {
    badgePrinterIPAddress,
    guestSelfRegistrationEnabled,
    guestImageCaptureEnabled,
  } = data?.getArrivalDisplaySettings || {};

  return {
    badgePrinterIPAddress,
    guestSelfRegistrationEnabled,
    guestImageCaptureEnabled,
    loading,
    error,
  };
};
