import { useArrivalDisplaySettings } from 'hooks';
import { createContext, ReactNode, useContext } from 'react';

export type ArrivalDisplaySettingsContextValue = {
  badgePrinterIPAddress?: string;
  guestImageCaptureEnabled?: boolean;
  guestSelfRegistrationEnabled?: boolean;
};

const ArrivalDisplaySettingsContext = createContext<
  ArrivalDisplaySettingsContextValue | undefined
>(undefined);

export const ArrivalDisplaySettingsContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const {
    badgePrinterIPAddress,
    guestImageCaptureEnabled,
    guestSelfRegistrationEnabled,
  } = useArrivalDisplaySettings();

  return (
    <ArrivalDisplaySettingsContext.Provider
      value={{
        badgePrinterIPAddress,
        guestImageCaptureEnabled:
          guestImageCaptureEnabled && !!badgePrinterIPAddress,
        guestSelfRegistrationEnabled,
      }}
    >
      {children}
    </ArrivalDisplaySettingsContext.Provider>
  );
};

export function useArrivalDisplaySettingsContext(): ArrivalDisplaySettingsContextValue {
  const context = useContext(ArrivalDisplaySettingsContext);
  if (!context) {
    throw new Error(
      'useArrivalDisplaySettingsContext must be used within a ArrivalDisplaySettingsContextProvider'
    );
  }
  return context;
}
