import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import styled from '@emotion/styled/macro';
import {
  Body05,
  Colors,
  Heading04,
  Heading02,
  Row,
  Box,
  Button,
} from '@robinpowered/design-system';
import {
  BadgeReprintModal,
  ContentWrapper,
  Avatar,
  Header,
  LoaderSmall,
} from 'components';
import {
  HealthCheckpointStatus,
  useApolloContext,
  useDeviceContext,
  usePageLoadedEvent,
  useVisitContext,
} from 'contexts';
import { GuestInviteStatus } from '__generated__/globalTypes';
import { useEffect, useState } from 'react';
import { useArrivalDisplaySettings } from 'hooks';

const REDIRECT_SECONDS_COMPLETE = 1000 * 10; // 10 seconds
const REDIRECT_SECONDS_FOR_FAILURE = 1000 * 30; // 30 seconds
const REDIRECT_SECONDS_FOR_BADGE_PRINTING = 1000 * 60; // 60 seconds

export const CheckInCompleted = (): JSX.Element => {
  const { t } = useTranslation('checkInCompleted');
  const [showReprintModal, setShowReprintModal] = useState(false);
  const history = useHistory();
  const { visit, loading, error, refetch, healthCheckpointStatus } =
    useVisitContext();
  const { bearerToken, tenantId } = useApolloContext();
  const { deviceName } = useDeviceContext();
  const { badgePrinterIPAddress } = useArrivalDisplaySettings();
  const hasFailedHCP = healthCheckpointStatus === HealthCheckpointStatus.FAILED;
  const isCheckedIn =
    visit?.status === GuestInviteStatus.CHECKED_IN ||
    visit?.status === GuestInviteStatus.CHECKED_OUT;
  const hostAvatar = visit?.host.avatar;
  const hostName = visit?.host.name;
  const hostEmail = visit?.host.email;
  const checkoutTime = visit?.checkoutOn
    ? moment(visit.checkoutOn).format('h:mm a')
    : null;
  // Extend the timeout for failed check-ins. They need to read this message.
  const redirectDuration = hasFailedHCP
    ? REDIRECT_SECONDS_FOR_FAILURE
    : badgePrinterIPAddress
    ? REDIRECT_SECONDS_FOR_BADGE_PRINTING
    : REDIRECT_SECONDS_COMPLETE;
  // Determine where to go next depending on what feaure is enabled.
  const nextRoute = '/';
  const pdfEndpoint =
    process.env.REACT_APP_PDF_URL ?? 'https://badges.robinpowered.com/pdf';

  usePageLoadedEvent('check-in-completed', isCheckedIn);

  // Using this approach instead of the timed redirect hook to handle cases where
  // visit object transitions from undefined => defined.
  useEffect(() => {
    const timerId = setTimeout(() => history.push(nextRoute), redirectDuration);
    return () => clearTimeout(timerId);
  }, [nextRoute]); // eslint-disable-line react-hooks/exhaustive-deps

  let bodyHeading = t('body_heading_host_notified', {
    name: hostName || t('them').toLowerCase(),
  });
  let bodyMessage = t('body_message_host_notified', {
    displayName: deviceName,
  });
  if (healthCheckpointStatus === HealthCheckpointStatus.FAILED) {
    bodyHeading = t('body_heading_hcp_failed', {
      name: hostName || t('them').toLowerCase(),
    });
    bodyMessage = hostEmail
      ? t('body_message_hcp_failed_with_email', {
          email: hostEmail,
        })
      : t('body_message_hcp_failed');
  } else if (visit?.status === GuestInviteStatus.CHECKED_OUT) {
    bodyHeading = t('body_heading_checked_out', { time: checkoutTime });
    bodyMessage = t('body_message_checked_out');
  }

  /**
   * Posts a message to the window using the postMessage API. The native application
   * wrapping this will be listening for this message and will use it to print the badge.
   */
  const printBadge = () => {
    if (window?.webkit?.messageHandlers?.printAction) {
      window.webkit.messageHandlers.printAction.postMessage(
        {
          visitId: visit?.visitId,
          printerIp: badgePrinterIPAddress,
          jwt: bearerToken,
          pdfEndpoint: pdfEndpoint,
          tenantId: tenantId,
          guestInviteId: visit?.id,
        },
        '*'
      );
    }
  };

  const renderLoading = () => (
    <div
      style={{
        display: 'flex',
      }}
    >
      <LoaderSmall />
    </div>
  );

  return (
    <>
      {showReprintModal && (
        <BadgeReprintModal
          onCancel={() => setShowReprintModal(false)}
          onPress={() => {
            setShowReprintModal(false);
            printBadge();
            history.push(nextRoute);
          }}
        />
      )}
      <Header
        nextDisabled={false}
        nextButtonText={t('done')}
        backHidden={true}
        onPrevClick={() => history.goBack()}
        onNextClick={() => history.push(nextRoute)}
      />
      <ContentWrapper
        isLoading={loading}
        error={error}
        refetch={refetch}
        renderLoading={renderLoading}
        boxStyle={{ padding: '32px 48px' }}
      >
        <HostRow>
          <Box mr="4">
            <Heading02 role="alert" style={{ marginBottom: '8px' }}>
              {bodyHeading}
            </Heading02>
            <Body05 color={Colors.Gray80}>{bodyMessage}</Body05>
          </Box>
          {hostAvatar && (
            <Avatar
              name={hostName || t('your_host')}
              imageSource={hostAvatar}
            />
          )}
        </HostRow>
        {badgePrinterIPAddress && !hasFailedHCP && (
          <>
            <Divider />
            <Heading04 mb="18px">{t('badge_issue_heading')}</Heading04>
            <Button
              variant="secondary"
              onClick={() => setShowReprintModal(true)}
            >
              {t('reprint_badge')}
            </Button>
          </>
        )}
      </ContentWrapper>
    </>
  );
};

const HostRow = styled(Row)`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  margin: 32px -48px;
  background-color: ${Colors.Tan70};
`;
