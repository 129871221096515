import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import {
  Header,
  HealthCheckpointCancellationModal,
  UnauthedServerError,
} from 'components';
import {
  HealthCheckpointStatus,
  useArrivalDisplaySettingsContext,
  usePageLoadedEvent,
  useSelfRegisteredGuestContext,
  useVisitContext,
} from 'contexts';
import { SurveyCompletionStatus } from 'constants/surveyCompletionStatus';
import { useTranslation } from 'react-i18next';
import { useDocumentDetailsWithCustomVisitType, useTimedRedirect } from 'hooks';
import { isMessageCompletionStatus } from 'utils';

const REDIRECT_SECONDS = 1000 * 60 * 3; // 3 minutes

export const GuestRegistrationHcp: React.FC = () => {
  const [checkpointCompletionStatus, setCheckpointCompletionStatus] =
    useState<SurveyCompletionStatus | null>(null);
  const [shouldCancelModalShow, setShouldCancelModalShow] =
    useState<boolean>(false);
  const HeaderRef = useRef<HTMLDivElement>(null);
  const FooterRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { healthCheckpointId } = useSelfRegisteredGuestContext();
  const { guestImageCaptureEnabled } = useArrivalDisplaySettingsContext();
  const { visit, setHealthCheckpointStatus } = useVisitContext();
  const { arrivalLocation, visitId, customVisitType } = visit ?? {};
  const surveyURL = `${process.env.REACT_APP_HEALTH_SURVEY_BASE_URL}${healthCheckpointId}`;
  const { t } = useTranslation(['common', 'healthCheckpoint']);
  const {
    data,
    error: documentDetailsError,
    refetch: refetchDocumentDetails,
  } = useDocumentDetailsWithCustomVisitType(
    customVisitType ?? '',
    arrivalLocation?.id ?? '',
    visitId ?? ''
  );
  const documentData = data?.visitDocumentWithNextDocumentIdWithCustomVisitType;

  usePageLoadedEvent('self-registration-health-checkpoint', false);
  useTimedRedirect('/', REDIRECT_SECONDS);

  /**
   * Calls the checkin mutation and puts the user on the pass/fail user flow.
   */
  const completeCheckIn = () => {
    if (checkpointCompletionStatus === SurveyCompletionStatus.FAILED) {
      setHealthCheckpointStatus(HealthCheckpointStatus.FAILED);
      history.push('/guest-registration/health-checkpoint-checking');
    } else {
      history.push(
        documentData
          ? '/guest-registration/document-agreement'
          : guestImageCaptureEnabled
          ? '/guest-registration/guest-image-capture'
          : '/guest-registration/check-in'
      );
    }
  };

  const handleSurveyCompletion = useCallback(
    (windowEvent: MessageEvent) => {
      const messageStringUncased: string =
        typeof windowEvent.data === 'string' ? windowEvent.data || '' : '';
      const messageString = messageStringUncased.toLowerCase();

      if (isMessageCompletionStatus(messageString)) {
        setCheckpointCompletionStatus(messageString);
      }
    },
    [setCheckpointCompletionStatus]
  );

  useEffect(() => {
    window.addEventListener('message', handleSurveyCompletion);
    return () => window.removeEventListener('message', handleSurveyCompletion);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkpointCompletionStatus) {
      completeCheckIn();
    }
  }, [checkpointCompletionStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // The iframe doesn't have the ability to flex or fill the page, resulting in a tiny
  // viewport with a scrollbar inside. We're forced to define fixed dimensions for it.
  const viewportHeight = window.parent.innerHeight;
  const headerHeight = HeaderRef.current?.clientHeight ?? 0;
  const footerHeight = FooterRef.current?.clientHeight ?? 0;
  const screenBodyHeight = viewportHeight - headerHeight - footerHeight;

  if (documentDetailsError) {
    return <UnauthedServerError onPressRetry={refetchDocumentDetails} />;
  }

  return (
    <>
      <Header
        ref={HeaderRef}
        nextHidden
        onPrevClick={() => setShouldCancelModalShow(true)}
        onNextClick={() => {}}
      />

      {shouldCancelModalShow && (
        <HealthCheckpointCancellationModal
          onCancel={() => setShouldCancelModalShow(false)}
          onConfirm={() => history.push('visit-type')}
        />
      )}

      {!checkpointCompletionStatus && (
        <FlexedFrame
          height={screenBodyHeight}
          isInteractionBlocked={shouldCancelModalShow}
          width="100%"
          frameBorder="0"
          src={surveyURL}
          title={t('healthCheckpoint:survey_title')}
        />
      )}
    </>
  );
};

const FlexedFrame = styled.iframe`
  flex: 1;

  ${({ isInteractionBlocked }: { isInteractionBlocked: boolean }) =>
    isInteractionBlocked && `pointer-events: none;`}
`;
