import { Body04, Heading01 } from '@robinpowered/design-system';
import { Sentry } from 'lib/sentry';
import { BoundedErrorBadge, ContentWrapper, Header } from 'components';
import {
  useApolloContext,
  useArrivalDisplaySettingsContext,
  useSelfRegisteredGuestContext,
  useVisitContext,
} from 'contexts';
import { useHistory } from 'react-router';
import { useCheckInSelfRegVisit, useTimedRedirect } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Severity } from '@sentry/react';
import { GuestInviteStatus } from '__generated__/globalTypes';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkit: any;
  }
}

const REDIRECT_SECONDS = 1000 * 5; // 5 seconds

export const GuestRegistrationCheckIn = (): JSX.Element => {
  const { t } = useTranslation('healthCheckpoint');
  const history = useHistory();
  const { visit, loading: visitLoading } = useVisitContext();
  const { setVisitId, setGuestInviteId, setHealthCheckpointId } =
    useSelfRegisteredGuestContext();
  const { bearerToken, tenantId } = useApolloContext();
  const { badgePrinterIPAddress } = useArrivalDisplaySettingsContext();
  const [checkIn, { error: checkInError, loading: checkInLoading }] =
    useCheckInSelfRegVisit();
  const redirectToCompletion = () =>
    setTimeout(
      () => history.push('/guest-registration/completed'),
      REDIRECT_SECONDS
    );
  const pdfEndpoint =
    process.env.REACT_APP_PDF_URL ?? 'https://badges.robinpowered.com/pdf';
  /**
   * Calls the check-in mutation for visits that have not yet been checked into,
   * and puts the user on the pass/fail user flow. If the user has already checked
   * into the visit, they are brought to the completed screen.
   */
  const completeCheckIn = () => {
    if (!visit) {
      Sentry.captureMessage(
        `Can't check into self-registered visit: Missing visit.`,
        Severity.Warning
      );
      history.push(`/completed`);
      return;
    }

    checkIn({
      variables: { guestInviteId: visit.id },
    }).then(() => {
      clearVisitIds();
      if (badgePrinterIPAddress) printBadge();
      redirectToCompletion();
    });
  };

  /**
   * Clear out client generated IDs when visit is checked into to prevent multiple
   * visit IDs being present and creating multiple visits.
   */
  const clearVisitIds = () => {
    setVisitId('');
    setGuestInviteId('');
    setHealthCheckpointId('');
  };

  /**
   * Posts a message to the window using the postMessage API. The native application
   * wrapping this will be listening for this message and will use it to print the badge.
   */
  const printBadge = () => {
    if (window?.webkit?.messageHandlers?.printAction) {
      window.webkit.messageHandlers.printAction.postMessage(
        {
          visitId: visit?.visitId,
          printerIp: badgePrinterIPAddress,
          jwt: bearerToken,
          pdfEndpoint: pdfEndpoint,
          tenantId: tenantId,
          guestInviteId: visit?.id,
        },
        '*'
      );
    }
  };

  useEffect(() => {
    if (visit && visit.status !== GuestInviteStatus.CHECKED_IN) {
      completeCheckIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visit]);

  useTimedRedirect();

  return (
    <>
      <Header
        backHidden
        nextDisabled={!checkInError && !visitLoading}
        isLoading={!checkInError || visitLoading || checkInLoading}
        onPrevClick={() => {}}
        onNextClick={completeCheckIn}
      />

      {checkInError && <BoundedErrorBadge message={t('check_in_error')} />}

      <ContentWrapper style={{ textAlign: 'center' }}>
        {!checkInError &&
          (badgePrinterIPAddress ? (
            <>
              <Heading01
                role="alert"
                mb="16px"
                style={{ fontSize: '26px', lineHeight: '36px' }}
              >
                {t('survey_passed.badge_printing_loading_message')}
              </Heading01>
              <Body04 style={{ fontSize: '16px', display: 'block' }}>
                {t('survey_passed.badge_printing_loading_sub_message')}
              </Body04>
            </>
          ) : (
            <Heading01
              role="alert"
              style={{ fontSize: '26px', lineHeight: '36px' }}
            >
              {t('survey_passed.loading_message')}
            </Heading01>
          ))}
      </ContentWrapper>
    </>
  );
};
