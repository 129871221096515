import {
  Body03,
  Button,
  Colors,
  Heading01,
  Heading04,
  SpinnerLoader,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import { Sentry } from 'lib/sentry';
import {
  BoundedErrorBadge,
  ContentWrapper,
  Header,
  UnauthedServerError,
} from 'components';
import {
  useArrivalDisplaySettingsContext,
  useDeviceContext,
  usePageLoadedEvent,
  useSelfRegisteredGuestContext,
  useVisitContext,
} from 'contexts';
import {
  useTimedRedirect,
  useDocumentDetailsWithCustomVisitType,
  useRegisterWithoutHcpMutation,
  useVisitTypes,
} from 'hooks';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { Severity } from '@sentry/react';

export const GuestRegistrationVisitType = (): JSX.Element => {
  const history = useHistory();
  const {
    guestName,
    guestEmail,
    guestHost,
    guestVisitType,
    guestInviteId,
    visitId,
    setGuestVisitType,
    setVisitId,
    setGuestInviteId,
    setHealthCheckpointId,
  } = useSelfRegisteredGuestContext();
  const { setVisitId: setVisitContextInviteId } = useVisitContext();
  const { guestImageCaptureEnabled } = useArrivalDisplaySettingsContext();
  const { t } = useTranslation(['common', 'guestRegistrationVisitType']);
  const [invalidDataError, setInvalidDataError] = useState(false);
  const { location } = useDeviceContext();
  const hcpRequired = location?.isHealthCheckpointRequired;
  const [
    registerWithoutHcpMutation,
    { error: registerWithoutHcpError, loading: registerWithoutHcpLoading },
  ] = useRegisterWithoutHcpMutation();
  const {
    data,
    loading: documentDetailsLoading,
    error: documentDetailsError,
    refetch: refetchDocumentDetails,
  } = useDocumentDetailsWithCustomVisitType(
    guestVisitType ?? '',
    location?.id ?? '',
    guestInviteId
  );
  const requiredDocumentToAgreeTo =
    data?.visitDocumentWithNextDocumentIdWithCustomVisitType;

  const {
    visitTypes,
    loading: visitTypesLoading,
    error: visitTypesError,
    refetch: refetchVisitTypes,
  } = useVisitTypes();

  usePageLoadedEvent('self-registration-visit-type', false);
  useTimedRedirect();

  useEffect(() => {
    if (!visitId) {
      setVisitId(uuidv4());
      setGuestInviteId(uuidv4());
      setHealthCheckpointId(uuidv4());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Checks if the required fields are present before putting the user on
   * one of the different user flows.
   *
   * If an health checkpoint is required,
   * send the user to the health checkpoint screen to register the visit.
   *
   * If it's not required, register the visit first.
   * Then either send them to agree to any required documents, take a guest image,
   * or check the user in.
   */
  const handleGuestRegistration = () => {
    if (location && guestHost && guestVisitType) {
      if (hcpRequired) {
        // The HCP loading screen will handle registering the visit.
        // It's self-contained because it requires polling for a successful registration result.
        history.push('/guest-registration/health-checkpoint-loading');
      } else {
        // Register the visit, then navigate away.
        const nextRoute = requiredDocumentToAgreeTo
          ? '/guest-registration/document-agreement'
          : guestImageCaptureEnabled
          ? '/guest-registration/guest-image-capture'
          : '/guest-registration/check-in';
        const variables = {
          guestName,
          guestEmail,
          startTime: moment.utc().toISOString(),
          arrivalLocationId: location.id,
          hostUserId: guestHost.id,
          visitType: guestVisitType,
          visitId,
          visitInviteId: guestInviteId,
        };

        registerWithoutHcpMutation({ variables }).then(() => {
          setVisitContextInviteId?.(guestInviteId);
          history.push(nextRoute);
        });
      }
    } else {
      setInvalidDataError(true);
      let missingDataTypes = '';
      if (!location) missingDataTypes += 'location,';
      if (!guestHost) missingDataTypes += 'guestHost,';
      if (!guestVisitType) missingDataTypes += 'guestVisitType,';
      Sentry.captureMessage(
        `Missing data for registering guest: ${missingDataTypes}`,
        Severity.Warning
      );
    }
  };

  if (documentDetailsError) {
    return <UnauthedServerError onPressRetry={refetchDocumentDetails} />;
  }

  return (
    <FlexWrapper>
      <Header
        nextButtonText={t('common:navigation.next')}
        nextDisabled={!guestVisitType}
        onPrevClick={() => history.push('/guest-registration/details')}
        onNextClick={handleGuestRegistration}
        isLoading={
          !location || registerWithoutHcpLoading || documentDetailsLoading
        }
      />

      {(registerWithoutHcpError || invalidDataError) && (
        <BoundedErrorBadge
          message={t('guestRegistrationVisitType:errors.registration_error')}
        />
      )}

      <ContentWrapper>
        <Heading01 mb="32px" id="heading">
          {t('guestRegistrationVisitType:heading')}
        </Heading01>
        <VisitTypesContainer>
          {visitTypesLoading && (
            <LoaderWrapper>
              <SpinnerLoader size={24} />
            </LoaderWrapper>
          )}

          {visitTypesError && !visitTypesLoading && (
            <ErrorWrapper>
              <Heading04 mb={3}>
                {t('guestRegistrationVisitType:errors.visit_type_loading')}
              </Heading04>
              <Button variant="secondary" onClick={() => refetchVisitTypes()}>
                {t('common:retry')}
              </Button>
            </ErrorWrapper>
          )}

          {!visitTypesLoading &&
            !visitTypesError &&
            visitTypes.map((visitType) => {
              const checked = visitType === guestVisitType;

              return (
                <Radio
                  key={visitType}
                  role="radio"
                  aria-checked={checked}
                  tabIndex={0}
                  checked={checked}
                  onClick={() => setGuestVisitType(visitType)}
                >
                  <VisitTypeLabel
                    color={checked ? Colors.White0 : Colors.Gray100}
                  >
                    {visitType}
                  </VisitTypeLabel>
                </Radio>
              );
            })}
        </VisitTypesContainer>
      </ContentWrapper>
    </FlexWrapper>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const VisitTypesContainer = styled.div`
  border: 1px solid ${Colors.Tan70};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Radio = styled.div<{ checked?: boolean }>`
  padding: 16px;
  text-align: center;
  align-self: stretch;
  border-bottom: 1px solid ${Colors.Tan70};
  cursor: pointer;
  ${(props) =>
    props.checked &&
    `
    background-color: ${Colors.Gray80};
    border-color: ${Colors.Gray80};
  `}
  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 8px 8px;
    border-bottom: 0;
  }
`;

const VisitTypeLabel = styled(Body03)`
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`.withComponent('label');

const LoaderWrapper = styled.div`
  padding: 100px 0;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
`;
